<template>
  <div>
<!--    <loader/>-->
    <b-row>
      <b-col lg="12" sm="12" v-if="$can('Collection Reports','Dashboard')">
        <b-card no-body>
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title class="mb-25">
                Month Wise Collection Reports
              </b-card-title>
              <b-card-text class="mb-0"></b-card-text>
            </div>
            <feather-icon
                icon="SettingsIcon"
                size="18"
                class="text-muted cursor-pointer"
            />
          </b-card-header>

          <b-card-body class="pb-0">
            <vue-chart :type="'line'" :data="chartData" :height="70" ></vue-chart>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="12" sm="12" v-if="$can('Expense Reports','Dashboard')">
        <b-card no-body>
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title class="mb-25">
                Month Wise Expense Reports
              </b-card-title>
              <b-card-text class="mb-0"></b-card-text>
            </div>
            <feather-icon
                icon="SettingsIcon"
                size="18"
                class="text-muted cursor-pointer"
            />
          </b-card-header>

          <b-card-body class="pb-0">
            <vue-chart :type="'line'" :data="chartData2" :height="70" ></vue-chart>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="4" sm="12" v-if="$can('Students','Dashboard')">
        <b-card-body>
          <b-card-header>
            <b-card-title>Students</b-card-title>
          </b-card-header>
          <!-- apex chart -->
          <vue-apex-charts
              type="donut"
              height="300"
              class="my-1"
              :options="student.chartOptions"
              :series="student.series"
          />
          <div
              class="d-flex justify-content-between"
              :class="'mb-0'"
          >
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="UsersIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Total" }}</span><span> {{ dashboard.total_student }}</span>
            </div>
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="UserCheckIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Male" }}</span><span> {{ dashboard.male_student }}</span>
            </div>
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="UserMinusIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Female" }}</span><span> {{ dashboard.female_student }}</span>
            </div>
          </div>
        </b-card-body>
      </b-col>
      <b-col lg="4" sm="12" v-if="$can('Employees','Dashboard')">
        <b-card-body>
          <b-card-header>
            <b-card-title>Employees</b-card-title>
          </b-card-header>
          <!-- apex chart -->
          <vue-apex-charts
              type="donut"
              height="300"
              class="my-1"
              :options="employee.chartOptions"
              :series="employee.series"
          />
          <div
              class="d-flex justify-content-between"
              :class="'mb-0'"
          >
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="BriefcaseIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Total" }}</span><span> {{ dashboard.total_employee }}</span>
            </div>
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="UserPlusIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Male" }}</span><span> {{ dashboard.male_employee }}</span>
            </div>
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="UserIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Female" }}</span><span> {{ dashboard.female_employee }}</span>
            </div>
          </div>
        </b-card-body>
      </b-col>
      <b-col lg="4" sm="12" v-if="$can('Employees','Dashboard')">
        <b-card-body>
          <b-card-header>
            <b-card-title>Employees Attendance</b-card-title>
          </b-card-header>
          <!-- apex chart -->
          <vue-apex-charts
              type="donut"
              height="300"
              class="my-1"
              :options="employee_attendances.chartOptions"
              :series="employee_attendances.series"
          />
          <div
              class="d-flex justify-content-between"
              :class="'mb-0'"
          >
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="BriefcaseIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Total" }}</span><span> {{ dashboard.total_employee }}</span>
            </div>
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="UserPlusIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Present" }}</span><span> {{ dashboard.employee_present }}</span>
            </div>
            <div class="series-info d-flex align-items-center">
              <feather-icon
                  icon="UserIcon"
                  size="16"
                  class="danger"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ "Absent" }}</span><span> {{ dashboard.employee_absent }}</span>
            </div>
          </div>
        </b-card-body>
      </b-col>
    </b-row>
    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col v-if="$can('Total Student','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            icon="UsersIcon"
            :statistic="dashboard.total_student"
            statistic-title="Total Student"
            color="info"
        />
      </b-col>
      <b-col v-if="$can('Male Student','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            color="warning"
            icon="UserCheckIcon"
            :statistic="dashboard.male_student"
            statistic-title="Male Student"
        />
      </b-col>
      <b-col v-if="$can('Female Student','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            color="danger"
            icon="UserMinusIcon"
            :statistic="dashboard.female_student"
            statistic-title="Female Student"
        />
      </b-col>
      <b-col v-if="$can('Total Employees','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            color="primary"
            icon="BriefcaseIcon"
            :statistic="dashboard.total_employee"
            statistic-title="Employees"
        />
      </b-col>
      <b-col v-if="$can('Male Employee','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            color="success"
            icon="UserPlusIcon"
            :statistic="dashboard.male_employee"
            statistic-title="Male Employee"
        />
      </b-col>
      <b-col v-if="$can('Female Employee','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="UserIcon"
            :statistic="dashboard.female_employee"
            statistic-title="Female Employee"
        />
      </b-col>
      <b-col v-if="$can('Exams','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="TwitchIcon"
            :statistic="dashboard.total_exam"
            statistic-title="Exams"
        />
      </b-col>
      <b-col v-if="$can('Exam Types','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="primary"
            icon="ToolIcon"
            :statistic="dashboard.total_exam_type"
            statistic-title="Exam Types"
        />
      </b-col>
      <b-col v-if="$can('Expense Types','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="TriangleIcon"
            :statistic="dashboard.total_expense_type"
            statistic-title="Expense Types"
        />
      </b-col>
      <b-col v-if="$can('Sub Expense','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="UmbrellaIcon"
            :statistic="dashboard.total_sub_expense"
            statistic-title="Sub Expense"
        />
      </b-col>
      <b-col v-if="$can('Expenses','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="TrendingUpIcon"
            :statistic="dashboard.total_expense"
            statistic-title="Expenses"
        />
      </b-col>
      <b-col v-if="$can('Subjects','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="SidebarIcon"
            :statistic="dashboard.total_subject"
            statistic-title="Subjects"
        />
      </b-col>
      <b-col v-if="$can('Departments','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="ServerIcon"
            :statistic="dashboard.total_department"
            statistic-title="Departments"
        />
      </b-col>
      <b-col v-if="$can('Designations','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="primary"
            icon="SlidersIcon"
            :statistic="dashboard.total_designation"
            statistic-title="Designations"
        />
      </b-col>
      <b-col v-if="$can('Scales','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="StarIcon"
            :statistic="dashboard.total_scale"
            statistic-title="Scales"
        />
      </b-col>
      <b-col v-if="$can('Grades','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="PieChartIcon"
            :statistic="dashboard.total_grade"
            statistic-title="Grades"
        />
      </b-col>
      <b-col v-if="$can('Bonus Types','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="primary"
            icon="LifeBuoyIcon"
            :statistic="dashboard.bonus_type"
            statistic-title="Bonus Types"
        />
      </b-col>
      <b-col v-if="$can('Fee Types','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="LinkIcon"
            :statistic="dashboard.total_fee_type"
            statistic-title="Fee Types"
        />
      </b-col>
      <b-col v-if="$can('Payment Options','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="info"
            icon="ShoppingCartIcon"
            :statistic="dashboard.payment_option"
            statistic-title="Payment Options"
        />
      </b-col>
      <b-col v-if="$can('Waiver Types','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="ShuffleIcon"
            :statistic="dashboard.waiver_type"
            statistic-title="Waiver Types"
        />
      </b-col>
      <b-col v-if="$can('Banks','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="primary"
            icon="SpeakerIcon"
            :statistic="dashboard.bank"
            statistic-title="Banks"
        />
      </b-col>
      <b-col v-if="$can('Accounts','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="OctagonIcon"
            :statistic="dashboard.total_account"
            statistic-title="Accounts"
        />
      </b-col>
      <b-col v-if="$can('Classes','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="primary"
            icon="CreditCardIcon"
            :statistic="dashboard.classes"
            statistic-title="Classes"
        />
      </b-col>
      <b-col v-if="$can('Versions','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="CpuIcon"
            :statistic="dashboard.version"
            statistic-title="Versions"
        />
      </b-col>
      <b-col v-if="$can('Shifts','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="info"
            icon="CropIcon"
            :statistic="dashboard.shift"
            statistic-title="Shifts"
        />
      </b-col>
      <b-col v-if="$can('Student Groups','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="DiscIcon"
            :statistic="dashboard.student_group"
            statistic-title="Student Groups"
        />
      </b-col>
      <b-col v-if="$can('Wings','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="info"
            icon="GiftIcon"
            :statistic="dashboard.wing"
            statistic-title="Wings"
        />
      </b-col>
      <b-col v-if="$can('Sections','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="primary"
            icon="GitlabIcon"
            :statistic="dashboard.section"
            statistic-title="Sections"
        />
      </b-col>
      <b-col v-if="$can('Academic Years','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="danger"
            icon="GitPullRequestIcon"
            :statistic="dashboard.academic_year"
            statistic-title="Academic Years"
        />
      </b-col>
      <b-col v-if="$can('Religions','Dashboard')"
          xl="2"
          md="4"
          sm="6"
      >
        <statistic-card-vertical
            hide-chart
            color="info"
            icon="HexagonIcon"
            :statistic="dashboard.religion"
            statistic-title="Religions"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import Loader from "@/views/Loader";
import { BRow, BCol,BCard, BCardHeader, BCardTitle, BCardBody, BCardText, } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
//import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
//import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
//import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import { kFormatter } from '@core/utils/filter'
//import CardStatisticOrderChart from './dashboard/admin/CardStatisticOrderChart.vue'
//import CardStatisticProfitChart from './dashboard/admin/CardStatisticProfitChart.vue'
//import CardStatisticsGroup from './dashboard/admin/CardStatisticsGroup.vue'
import apiCall from "@/libs/axios";
import VueChart from 'vue-chart-js';
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
export default {
  components: {
    BRow,VueChart,
    BCol,BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
    StatisticCardVertical,VueApexCharts,//Loader,
    //StatisticCardHorizontal,
    //StatisticCardWithAreaChart,
    //StatisticCardWithLineChart,
    //CardStatisticOrderChart,
    //CardStatisticProfitChart,
    //CardStatisticsGroup,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Reports',
            backgroundColor: 'blue',
            data: []
          },
        ],
      },
      chartData2: {
        labels: [],
        datasets: [
          {
            label: 'Reports',
            backgroundColor: 'blue',
            data: []
          },
        ],
      },
      dashboard:[],
      student: {
        series: [58.6, 34.9, 6.5],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Total Student', 'Male', 'Female'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        },
      },
      employee: {
        series: [58.6, 34.9, 6.5],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Total Employee', 'Male', 'Female'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        },
      },
      employee_attendances: {
        series: [0, 0, 0],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Total Employee', 'Present', 'Absent'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [0, 0, 0],
          stroke: { width: 0 },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        },
      },
    }
  },
  methods: {
    kFormatter,
    getDashboard(){
      apiCall.get('/get/dashboard/information').then((response)=>{
        this.dashboard=response.data;
      }).catch(()=>{
        this.dashboard=[];
      })
    },
    setCollection(){
      if(this.dashboard.income_graph){
        this.chartData.datasets[0].data=this.dashboard.income_graph.count;
        this.chartData.labels=this.dashboard.income_graph.month;
      }else{
        this.chartData.datasets[0].data=[];
        this.chartData.labels=[];
      }
    },
    setExpense(){
      if(this.dashboard.expense_graph){
        this.chartData2.datasets[0].data=this.dashboard.expense_graph.count;
        this.chartData2.labels=this.dashboard.expense_graph.month;
      }else{
        this.chartData2.datasets[0].data=[];
        this.chartData2.labels=[];
      }
    },
    setEmployee(){
      if(this.dashboard){
        this.employee.series= [this.dashboard.total_employee??0, this.dashboard.male_employee??0, this.dashboard.female_employee??0];
      }else{
        this.employee.series=[5,2,3];
      }
    },
    setEmployeeAttendance(){
      if(this.dashboard){
        this.employee_attendances.series= [this.dashboard.total_employee??0, this.dashboard.employee_present??0, this.dashboard.employee_absent??0];
      }else{
        this.employee_attendances.series=[5,2,3];
      }
    },
    setStudent(){
      if(this.dashboard){
        this.student.series= [this.dashboard.total_student??0, this.dashboard.male_student??0, this.dashboard.female_student??0];
      }else{
        this.student.series=[5,2,3];
      }
    },
  },
  created(){
    this.getDashboard();
    this.setCollection();
    this.setExpense();
    this.setEmployee();
    this.setEmployeeAttendance();
    this.setStudent();
  },
  watch: {
    dashboard() {
      this.setCollection();
      this.setExpense();
      this.setEmployee();
      this.setEmployeeAttendance();
      this.setStudent();
    }
  },
}
</script>

<style>

</style>
